<template>
  <div></div>
</template>

<script>
export default {
  name: "Logout",
  created() {
    this.setup();
  },
  methods: {
    async setup() {
      const responseKind = this.$route.query.kind;
      const responseMsg = this.$route.query.message;

      const title = "Signing out";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      if (responseKind || responseMsg) {
        await this.$store.dispatch("logoutSSO", {
          kind: responseKind,
          message: responseMsg
        });
      }

      const titleSuccess = "Logout";
      const subtitleSuccess = "You have been successfully logged out";
      await this.$store.dispatch("pushSuccess", {
        title: titleSuccess,
        subtitle: subtitleSuccess
      });

      await this.$store.dispatch("logout");
    }
  }
};
</script>

<style scoped lang="scss"></style>
